<template>
  <div>
    <div class="bannerContainer">
      <div class="w" :class="$i18n.locale == 'en' ? 'en_banner' : 'zh_banner'">
        <div class="title">{{ $t('行业解决方案') }}</div>
        <div class="info">
          {{ $t('基于AI核心算法，深入行业应用，解决用户痛点。') }}
        </div>
      </div>
    </div>

    <div class="AIScaleApplicationScenario">
      <div class="title">{{ $t('助力传统电子秤快速实现AI打秤应用场景') }}</div>
      <div class="topContainer">
        <div class="w" :class="$i18n.locale == 'en' ? 'en_top' : 'zh_top'">
          <div>{{ $t('OSAI软件') }}</div>
          <div>{{ $t('传统电子秤') }}</div>
          <div>{{ $t('AI智能识别') }}</div>
        </div>
      </div>
      <div class="bottom w">
        <div>
          <div class="item">
            <div class="i"></div>
            <div class="text">
              {{ $t('自动匹配或自动拍摄商品展示图片，并支持手动编辑。') }}
            </div>
          </div>
          <div class="item">
            <div class="i"></div>
            <div class="text">
              {{ $t('支持市面主流的POS收银软件，远程取数、云端自动升级。') }}
            </div>
          </div>
          <div class="item">
            <div class="i"></div>
            <div class="text">
              {{ $t('自动精准识别，支持拼音首字母或PLU号等商品助记符搜索。') }}
            </div>
          </div>
          <div class="item">
            <div class="i"></div>
            <div class="text">
              {{ $t('傲视AI视觉识别核心算法，适应不同光线环境及多种秤盘。') }}
            </div>
          </div>
        </div>
        <div class="img">
          <img src="../../../assets/行业解决方案/界面配图.png" alt="" />
        </div>
      </div>
    </div>

    <div class="hardwareSystem">
      <div class="title">
        {{ $t('对接多种收银系统，快速集成AI视觉识别软硬件系统') }}
      </div>
      <div class="top">
        <div class="img">
          <img src="../../../assets/行业解决方案/分组 12.png" alt="" />
        </div>
      </div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.bannerContainer {
  background: url(../../../assets/行业解决方案/首页配图.png) no-repeat center;
  background-size: cover;
  .zh_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    color: #fff;
    .title {
      line-height: 104.26px;
      font-size: 72px;
      font-weight: 700;
    }
    .info {
      font-size: 24px;
      height: 83px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
  .en_banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    color: #fff;
    .title {
      line-height: 83.98px;
      font-size: 58px;
      font-weight: 700;
    }
    .info {
      font-size: 24px;
      width: 592px;
      font-size: 24px;
      line-height: 34.75px;
    }
  }
}
.AIScaleApplicationScenario {
  .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 43.44px;
    background: #f5f6fa;
    padding: 28px 0px;
    display: flex;
    justify-content: center;
    color: #344f98;
  }
  .topContainer {
    background: url(../../../assets/行业解决方案/应用场景配图.png) no-repeat
      center;
    background-size: cover;
    .zh_top {
      height: 480px;
      padding: 414px 225px 0px 232px;
      display: flex;
      justify-content: space-between;
      div {
        color: #808080;
        font-size: 24px;
        font-weight: 500;
        line-height: 34.75px;
      }
    }
    .en_top {
      height: 480px;
      padding: 414px 239px 0px 211.5px;
      display: flex;
      justify-content: space-between;
      div {
        color: #808080;
        font-size: 24px;
        font-weight: 500;
        line-height: 34.75px;
      }
    }
  }
  .bottom {
    padding-left: 124px;
    padding-right: 86px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 500px;
    .img {
      width: 536px;
      height: 398px;
      img {
        width: 100%;
      }
    }
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 33px;
      .i {
        margin-right: 6px;
        border-radius: 50% 50% 50% 50%;
        background: #2a82e4;
        border: 2px solid #ffffff;
        box-shadow: 0px 2px 4px #cbddf4;
        width: 18px;
        height: 18px;
      }
      .text {
        line-height: 28.96px;
        font-size: 20px;
        color: #808080;
      }
    }
  }
}

.hardwareSystem {
  .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 43.44px;
    background: #f5f6fa;
    padding: 28px 0px;
    display: flex;
    justify-content: center;
    color: #344f98;
  }
  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 423px;
    .img {
      width: 458px;
      height: 249px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .bottom {
    background: url(../../../assets/行业解决方案/底部配图.png) no-repeat center;
    background-size: cover;
    height: 587px;
  }
}
</style>
